// config.js
const config = {
    // apiUrl: 'http://localhost:3001',
    apiUrl: 'https://nncargo.kz:3001',
    
    nameCargo: 'NN CARGO'
  };
  
  
export default config;
  